.container {
  position: absolute;
  top: 60px;
  height: calc(100% - 60px);
  width: 100%;
  display: flex;
  overflow-y: scroll;
  overflow: overlay;
  -webkit-overflow-scrolling: touch;
  justify-content: center;

  .content {
    width: 90%;

    p {
      margin: 10px 0;
      font-size: 20px;
      text-align: center;
      font-weight: 700;
    }

    span {
      font-style: italic;
      font-size: 12px;
    }
  }
}

@import "./variables";

.vehicle-name {
  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  line-height: 100%;
  font-size: 28px;
  text-align: center;
  /* margin-bottom: 20px; */ // change according to device
  text-transform: uppercase;
  font-weight: 800;
}

.container {
  color: #c6b791;
  display: flex;
  justify-content: center;
  height: calc(100% - 60px);
  margin-top: 52px;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;

  @include for-size(phone-only) {
    height: calc(100% - 120px);
  }

  .honeycomb {
    @include for-size(phone-landscape-up) {
      transform: rotate(-90deg);
      margin-top: -60px;
      margin-left: 20px;
    }
  }

  .hidden {
    visibility: hidden;
  }
}

.first-row {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-column-gap: 10px;
  width: 210px;
  margin: auto;
  margin-top: -20px;

  @include for-size(phone-landscape-up) {
    grid-template-columns: repeat(2, 70px);
    margin: auto;
    margin-top: -20px;
    width: 150px;
  }
}
.second-row {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-column-gap: 10px;
  width: 320px;
  margin: auto;
  margin-top: -20px;

  @include for-size(phone-landscape-up) {
    grid-template-columns: repeat(3, 70px);
    margin: auto;
    margin-top: -12.5px;
    width: 230px;
  }
}
.third-row {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-column-gap: 10px;
  width: 210px;
  margin: auto;
  margin-top: -20px;

  @include for-size(phone-landscape-up) {
    grid-template-columns: repeat(2, 70px);
    margin: auto;
    margin-top: -12.5px;
    width: 150px;
  }
}
.fourth-row {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-column-gap: 10px;
  width: 320px;
  margin: auto;
  margin-top: -20px;

  @include for-size(phone-landscape-up) {
    grid-template-columns: repeat(3, 70px);
    margin: auto;
    margin-top: -12.5px;
    width: 230px;
  }
}

P {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  text-align: center;
  margin-top: -10px;
}

a.link {
  color: #c6b791;
  text-decoration: none;
  -webkit-touch-callout: none;
}

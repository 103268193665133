@media only screen and (max-width: 330px) {
  .dd .languagebox {
    font-size: 1.05em;
  }
}
@media only screen and (min-width: 320px) and (max-width: 639px) {
  .languagebox {
    font-size: 1.12em;
  }
  #restart {
    font-size: 40px;
  }
}
@media only screen and (min-width: 640px) and (max-width: 767px) {
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
}

button:focus {
  outline: none;
}
button {
  cursor: pointer;
}

.material-icons {
  cursor: pointer;
  border: 1px solid #c6b791;
  border-radius: 10px;
  padding: 2px;
  font-size: 40px;
  color: #c6b791;
}

.languagebox {
  cursor: pointer;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #c6b791;
}

.dd {
  z-index: 10;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #444851;
  transition: background-color 0.5s;
}

.icon {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #444851;
  transition: background-color 0.5s;

  i {
    color: #c6b791;
  }
}

.burger {
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #444851;
  transition: background-color 0.5s;

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: #c6b791;
  }
}

.headerBody {
  height: 60px;
  width: 100%;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
}

.sidenav-header-bg {
  background-color: #f2f2f2;
  transition: background-color 0.5s;
}

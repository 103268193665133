$gold: #c6b791;
$gray: #484c55;

$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: 900px;
$tablet-landscape-upper-boundary: 1200px;
$desktop-upper-boundary: 1800px;
$smallest-phone-height: 480px;

// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
@mixin for-size($range) {
  @if $range == phone-only {
    @media only screen and (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == phone-landscape-up {
    @media only screen and (max-height: $smallest-phone-height) and (min-width: $smallest-phone-height) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media only screen and (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media only screen and (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media only screen and (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media only screen and (min-width: $desktop-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-portrait-max {
    @media only screen and (max-width: #{$tablet-portrait-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-landscape-max {
    @media only screen and (max-width: #{$tablet-landscape-upper-boundary - 1}) {
      @content;
    }
  }
}

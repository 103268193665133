@import "./variables";

.container {
  width: 100%;

  .content {
    height: calc(100% - 50px);
  }
}

.description {
  width: 50%;
  margin: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 16px;

  @include for-size(phone-only) {
    width: 90%;
  }

  p {
    text-align: left;
    font-family: "Roboto";
    font-size: 16px;
    line-height: 20px;
  }
}

.product-title {
  text-align: center;
  height: 50px;
  padding: 18px;
  margin: 0 18px 28px 18px;
  font-size: 24px;
  line-height: normal;
  height: 67px;
  font-weight: bold;
}

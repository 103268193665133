.container {
  display: flex;
  justify-content: center;
  margin-top: 3px;
}

.recommendation-text {
  width: 200px;
  text-align: center;
  font-weight: 700;
  padding: auto;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
}

.recommended {
  /* background-color: rgba(161, 204, 157, 0.3); */
  /* border: 3px solid rgb(144, 191, 140); */
  /* color: rgb(144, 191, 140); */
  /* animation: changeTextColor 4s alternate infinite; */
}

.alternative {
  /* background-color: rgba(191, 190, 140, 0.3); */
  /* border: 3px solid rgb(191, 190, 140);
  color: rgb(191, 190, 140); */
}

/* BORDER ANIMATION */

.recommended,
.alternative {
  --border-width: 3px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  background: #484c55;
  border-radius: var(--border-width);
}

.recommended::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    0.25turn,
    hsl(124, 82%, 56%),
    hsl(145, 82%, 56%),
    hsl(179, 85%, 66%),
    hsl(224, 85%, 66%),
    hsl(179, 85%, 66%),
    hsl(145, 82%, 56%),
    hsl(124, 82%, 56%)
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 3s alternate infinite;
}

.alternative::after {
  position: absolute;
  content: "";
  top: calc(-1 * var(--border-width));
  left: calc(-1 * var(--border-width));
  z-index: -1;
  width: calc(100% + var(--border-width) * 2);
  height: calc(100% + var(--border-width) * 2);
  background: linear-gradient(
    0.25turn,
    hsl(55, 82%, 56%),
    hsl(44, 85%, 66%),
    hsl(25, 82%, 56%),
    hsl(359, 85%, 66%),
    hsl(25, 82%, 56%),
    hsl(44, 85%, 66%),
    hsl(55, 82%, 56%) /* hsl(269, 85%, 66%), */
  );
  background-size: 300% 300%;
  background-position: 0 50%;
  border-radius: calc(2 * var(--border-width));
  animation: moveGradient 2s alternate infinite;
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

/* @keyframes changeTextColor {
  0% {
    color: hsl(89, 85%, 66%);
  }

  50% {
    color: hsl(134, 85%, 66%);
  }

  100% {
    color: hsl(179, 85%, 66%);
  }
} */

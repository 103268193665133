.slick-prev:before,
.slick-next:before {
  color: #c6b791;
  font-size: 30px;
  position: absolute;
}

.slick-prev::before {
  left: 10px;
}

.slick-next::before {
  right: 10px;
}

.slick-prev {
  width: 30px;
  height: 30px;
  z-index: 2;
}
.slick-next {
  width: 30px;
  height: 30px;
  z-index: 2;
}
.slick-dots {
  position: unset;
}

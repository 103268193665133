.sidenav {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 0;
  z-index: 3;
  display: grid;
  align-items: center;
  transition: 0.5s;
}

.appear {
  background-color: rgba(255, 255, 255, 1);
  width: 100vw;
  align-items: center;
  transition: 0.5s;
}

.navtitles {
  text-align: center;
}

.feedback {
  opacity: 0.5;
  cursor: default;
}

.navtitles a,
.navtitles div {
  font-size: 18px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* white-space: nowrap; */ /* opening sidenav breaks from 1 to 2 lines in some languages  */
  text-decoration: none;
  color: #484c55;
  padding: 5px;
  cursor: pointer;
  border-bottom: 1px solid #c6b791;

  &:first-child {
    border-top: 1px solid #c6b791;
  }
}

.navdimmer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  transition: 0.5s;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dimbackground {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

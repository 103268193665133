body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: #484c55;
  color: #c6b791;
  font-family: "Manrope", sans-serif;
  letter-spacing: 1px;
}

#root {
  height: 100%;
  width: 100%;
}

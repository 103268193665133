.vehicle-info {
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  height: 26px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.vehicle-info div {
  padding: 3px;
  text-decoration: none;
  color: #c6b791;
  font-family: "Manrope", sans-serif;
  font-weight: 700;
}

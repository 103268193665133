@import "./variables";

.item-dim {
  opacity: 0.3;
}

.hexagon {
  position: relative;
  width: 100px;
  height: 57.74px;
  margin: 28.87px 0;
  border-left: solid 5px #c6b791;
  border-right: solid 5px #c6b791;

  @include for-size(phone-landscape-up) {
    width: 70px;
    height: 40.41px;
    margin: 20.21px 0;
  }

  .vehicle-img {
    width: 60px;
    height: 60px;
    position: relative;
    display: block;
    margin: auto;
    -webkit-touch-callout: none;

    @include for-size(phone-landscape-up) {
      transform: rotate(90deg);
      width: 40px;
      height: 40px;
    }
  }

  p {
    text-transform: uppercase;
  }
}

.hexagon:before,
.hexagon:after {
  content: "";
  position: absolute;
  z-index: 1;
  width: 70.71px;
  height: 70.71px;
  -webkit-transform: scaleY(0.5774) rotate(-45deg);
  -ms-transform: scaleY(0.5774) rotate(-45deg);
  transform: scaleY(0.5774) rotate(-45deg);
  background-color: inherit;
  left: 9.6447px;

  @include for-size(phone-landscape-up) {
    width: 49.5px;
    height: 49.5px;
    left: 5.2513px;
  }
}

.hexagon:before {
  top: -35.3553px;
  border-top: solid 7.0711px #c6b791;
  border-right: solid 7.0711px #c6b791;

  @include for-size(phone-landscape-up) {
    top: -24.7487px;
  }
}

.hexagon:after {
  bottom: -35.3553px;
  border-bottom: solid 7.0711px #c6b791;
  border-left: solid 7.0711px #c6b791;

  @include for-size(phone-landscape-up) {
    bottom: -24.7487px;
  }
}

.hexagon-solo {
  width: 250px;
  height: 144.34px;
  margin: 72.17px 0;
  border-left: solid 10px #c6b791;
  border-right: solid 10px #c6b791;

  @include for-size(phone-landscape-up) {
    width: 150px;
    height: 86.6px;
    margin: 43.3px 0;
  }

  .vehicle-img {
    width: 120px;
    height: 120px;
    top: 15px;

    @include for-size(phone-landscape-up) {
      width: 100px;
      height: 100px;
      top: -5px;
      transform: rotate(0deg);
    }
  }

  &::before,
  &::after {
    width: 176.78px;
    height: 176.78px;
    left: 26.6117px;

    @include for-size(phone-landscape-up) {
      width: 106.07px;
      height: 106.07px;
      left: 11.9670px;
    }
  }

  &::before {
    top: -88.3883px;
    /* border-top: solid 7.0711px #c6b791;
    border-right: solid 7.0711px #c6b791; */
    border-top: solid 14.1421px #c6b791;
    border-right: solid 14.1421px #c6b791;

    @include for-size(phone-landscape-up) {
      top: -53.033px;
    }
  }

  &::after {
    bottom: -88.3883px;
    /* border-bottom: solid 7.0711px #c6b791;
    border-left: solid 7.0711px #c6b791; */
    border-bottom: solid 14.1421px #c6b791;
    border-left: solid 14.1421px #c6b791;

    @include for-size(phone-landscape-up) {
      bottom: -53.033px;
    }
  }
}

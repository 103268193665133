@import "./variables";

.accordion {
  &__item {
    margin: 30px 20%;

    @include for-size(phone-only) {
      margin: 30px 10px;
    }

    @include for-size(phone-landscape-up) {
      margin: 30px 10px;
    }
  }

  &__button {
    font-size: 18px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    background-color: #383b42;
    box-shadow: 0 6px #2c2f36;

    &:active {
      transform: translateY(3px);
      -webkit-transform: translateY(3px);
      box-shadow: 0 3px #23262b;
    }

    &:focus {
      outline: none;
    }

    &[aria-expanded="false"] {
      display: flex;
      justify-content: space-between;

      &::before {
        content: "\25B8";
      }

      &::after {
        content: "";
      }
    }

    &[aria-expanded="true"] {
      display: flex;
      justify-content: space-between;

      &::before {
        content: "\25BE";
      }

      &::after {
        content: "";
      }
    }
  }

  &__panel {
    background-color: #40444d;
    padding: 10px;
    margin-top: 6px;
  }
}

.product__image {
  height: 400px;
  min-height: 300px;
  width: auto;
  margin: 10px auto;
  display: block;

  @include for-size(phone-only) {
    height: 300px;
  }
}

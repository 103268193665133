@import "./variables";

.container {
  position: absolute;
  top: 86px;
  height: calc(100% - 90px);
  width: 90%;
  display: grid;
  justify-content: center;
  align-content: center;
  margin: auto;
  left: 5%;
}
.container div {
  min-width: 0px;
  min-height: 0px;
}

.img {
  height: 400px;
  min-height: 300px;
  width: auto;
  margin: 10px auto;
  display: block;

  @include for-size(phone-only) {
    height: 300px;
  }
}

.slider-item:focus {
  outline: none;
}

.slider-item-text {
  text-align: center;
}

h3 {
  text-align: center;
  font-size: 1.5em;
}
.linkDiv {
  width: fit-content;
  margin: auto;

  button {
    background-color: rgba(1, 1, 1, 0);
    color: $gold;
    padding: 8px 12px;
    border: 2px solid $gold;
    border-radius: 5px;
    font-family: "Manrope", sans-serif;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
  }
}

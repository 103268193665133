.label-and-input{
    margin: 20px;
}

.calculation{
    margin: 20px;
}

.calc-btn{
    margin: 10px 0;
}

.nota-bene {
    margin-left: 20px;
}

.polartherm-title{
    font-size: 26px;
    font-weight: 600;
    margin: 20px 0 0 20px;
}

.result-container{
    border: 1px solid black;
    background-color: yellow;
    padding: 10px;
    font-size: 20px;
    width: 200px;
}